export const environment = {
    production: true,
    GOOGLE_CLIENT_ID: '62198175306-h477rqb0lmglnlolib9pn2m9pjudrm2h.apps.googleusercontent.com',
    IP_FIND_KEY: '89faecdb-c41e-4ba0-8eb3-6dd1a731eddc',
    ROOT_URL: 'https://us1.fylehq.com',
    APP_URL: 'https://co.fyle.co/app',
    APP_ROUTER_URL: 'https://accounts.fylehq.com',
    ROUTER_URL: 'https://accounts.fylehq.com',
    SENTRY_DSN: 'https://971bdf3cefe24ef88d6e1cbb65b5d535@sentry.io/3554166',
    SEND_ERROR_TO_SENTRY: true,
    SENTRY_TRACES_SAMPLE_RATE: 0.1,
    ENVIRONMENT: 'capital_one_staging',
    RELEASE: '3f645230530f438af633eb68f4499f6a2352c126',
    SEGMENT_SOURCE_ID: 'DOMzNqPitYhhGkOe1UyUOWaGK3jnCvIT',
    TARGET: 'capital_one',
    FW_ENVIRONMENT: 'undefined',
    GOOGLE_MAPS_API_KEY: 'AIzaSyD0CS8puRtuubkv94qp-1JREMQtfxx7Cns',
    DWOLLA_ENVIRONMENT: 'sandbox',
    LAUNCH_DARKLY_CLIENT_ID: '61fb848da00c1016c73fbe55',
  };