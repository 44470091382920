<div class="auth-container new-password-container">

  <div class="card-container">

    <app-header></app-header>

    <div class="auth-card">

      <h3 class="title">Reset Password</h3>
      <p class="sub-title">Enter the new password below</p>

      <div *ngIf="isResetPasswordFailed" class="error-message-box">
        This link has expired. Please use the <a class="reset-password" (click)="goToResetPassword()">reset password page</a> to request for a new link.
      </div>

      <div *ngIf="!isResetPasswordFailed" class="tw-mt-16-px">
        <app-password-rules [passwordStrength]="passwordForm.controls.newPassword.errors"></app-password-rules>
      </div>

      <form class="form-container" #passwordFormElement [formGroup]="passwordForm">

        <div class="new-password-container">

          <div class="password-label tw-flex tw-items-end tw-justify-between">
            <div class="tw-flex tw-flex-row">
              <label class="input-label">Enter new password</label>
              <span class="asterisk">*</span>
            </div>
          </div>

          <div class="password-input tw-relative">
            <input
              formControlName="newPassword"
              appAutofocus
              autocomplete="current-password"
              type="{{showPlainPassword ? 'text' : 'password'}}"
              [ngClass]="{'p-error' : passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.invalid}"
              placeholder="Enter your new password here" 
              class="input-content input-text"
            >
            <div class="error-space">
            </div>

            <div *ngIf="!showPlainPassword" class="action-wrapper" (click)="showPlainPassword = !showPlainPassword">
              <span class="action-text">
                <app-svg-sprite icon="eye-slash" class="icon common-icon"></app-svg-sprite>
              </span>
            </div>
            <div *ngIf="showPlainPassword" class="action-wrapper" (click)="showPlainPassword = !showPlainPassword">
              <span class="action-text">
                <app-svg-sprite icon="eye" class="icon common-icon"></app-svg-sprite>
              </span>
            </div>
            
            <ng-container *ngIf="passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.errors">
              <div *ngIf="passwordForm.controls.newPassword.hasError('required')" class="error">Please enter your password</div>
            </ng-container>
            
          </div>

        </div>

        <div class="confirm-password-container">

          <div class="password-label tw-flex tw-items-end tw-justify-between">
            <div class="tw-flex tw-flex-row">
              <label class="input-label">Re-enter new password</label>
              <span class="asterisk">*</span>
            </div>
          </div>

          <div class="password-input tw-relative">
            <input
              formControlName="confirmPassword"
              autocomplete="confirm-password"
              type="{{showPlainConfirmPassword ? 'text' : 'password'}}"
              [ngClass]="{'p-error' : passwordForm.controls.confirmPassword.touched && passwordForm.controls.confirmPassword.invalid}"
              placeholder="Re-enter your new password here" 
              class="input-content input-text"
            >
            <div class="error-space">
            </div>

            <div *ngIf="!showPlainConfirmPassword" class="action-wrapper" (click)="showPlainConfirmPassword = !showPlainConfirmPassword">
              <span class="action-text">
                <app-svg-sprite icon="eye-slash" class="icon common-icon"></app-svg-sprite>
              </span>
            </div>
            <div *ngIf="showPlainConfirmPassword" class="action-wrapper" (click)="showPlainConfirmPassword = !showPlainConfirmPassword">
              <span class="action-text">
                <app-svg-sprite icon="eye" class="icon common-icon"></app-svg-sprite>
              </span>
            </div>

            <ng-container *ngIf="passwordForm.controls.confirmPassword.touched && passwordForm.hasError('mismatch')">
              <div class="action-wrapper tw-mr-32-px">
                <span class="action-text">
                  <app-svg-sprite icon="danger-fill" class="error-icon common-icon"></app-svg-sprite>
                </span>
              </div>
            </ng-container>
            
            <ng-container *ngIf="passwordForm.controls.confirmPassword.touched && passwordForm.controls.confirmPassword.errors">
              <div *ngIf="passwordForm.controls.confirmPassword.hasError('required') && !passwordForm.hasError('mismatch')" class="error">Please re-enter your password</div>
            </ng-container>

            <ng-container *ngIf="passwordForm.controls.confirmPassword.touched && passwordForm.hasError('mismatch')">
              <div class="error">The re-entered password does not match</div>
            </ng-container>
          
          </div>
        </div>

        <fdl-button
          [validateForm]="true"
          [isLoading]="showLoaderOnButton"
          [loadingText]="'Resetting'"
          [formToValidate]="passwordForm"
          [formElement]="passwordFormElement"
          [customClasses]="'font-700 padding-14'"
          [defaultType]="'submit'"
          [buttonType]="buttonType.PRIMARY"
          (buttonClick)="savePassword()" 
          [label]="'Set password'"
        >
        </fdl-button>
        
      </form>

      <div class="alternate-text" (click)="goToSignIn()">
        <p>&#60;</p>
        <div>Back to sign in</div>
      </div>

    </div>
  </div>
  <footer class="footer">Never lose track</footer>
</div>
